<template>
    <ShopOrders :orders="orders"/>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { orders } from '@/services/firebase';
import ShopOrders from '@/components/ShopOrders/ShopOrders.vue';

export default defineComponent({
    name: 'OrderCanceled',
    components: { ShopOrders },
    setup() {
        return {orders: orders.canceled}
    },
});
</script>
